import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

const FibromyalgiaChronicPain = ({ data }) => (
  <Layout>
    <SEO title="Fitness and Spirit- My Story" />
    <div className="container-main">
      <div className="container-main_div">
        <h1>Fibromyalgia and Chronic Pain</h1>
        <h2
          style={{
            textAlign: 'center',
            paddingTop: '5px',
            fontFamily: 'Great Vibes, cursive',
            fontSize: '2em',
            fontWeight: 700,
          }}
        >
          You are not alone.
        </h2>
        <Img
          style={{
            margin: ' 40px auto',
            textAlign: 'center',
            display: 'block',
          }}
          imgStyle={{ textAlign: 'center' }}
          fixed={data.file.childImageSharp.fixed}
        />
        <p>
          More than 1 in 3 American adults are impacted by a life-altering
          chronic pain condition, and 1 in 9 suffer with severe, disabling pain.
        </p>
        <p>
          Fibromyalgia (pronounced fy-bro-my-AL-ja) is a complex chronic pain
          disorder that affects an estimated 2-4% of the world's population
          (~210 million in 2015; 3%). For example, In the United States,
          approximately 10 million Americans (3%) are afflicted with FM. While
          it occurs most often in women (80% women - 20% men), FM also strikes
          men and children of all ethnic backgrounds. For people with severe
          symptoms, fibromyalgia (FM) can be extremely debilitating and
          interfere with basic daily activities. Fibromyalgia is a condition
          which appears to involve disordered central afferent processing. This
          processing includes neuroplasticity (a physical change in the brain)
          that engages regions of the brain abnormally in processing external
          stimuli. The cause of FM remains uncertain.
        </p>
        <h2>Symptoms</h2>
        <p>
          Fibromyalgia is a condition which appears to involve disordered
          central afferent processing. The major symptoms of FM include:
        </p>
        <ul>
          <li>chronic, widespread body pain</li>
          <li>moderate to severe fatigue</li>
          <li>sleep disorders and disturbances</li>
          <li>problems with cognitive functioning and memory</li>
        </ul>
        <p>Other symptoms may include:</p>
        <ul>
          <li>psychological distress (anxiety and depression)</li>
          <li>impaired functionality</li>
          <li>sensitivity to light, sound, and odors</li>
          <li>stiffness upon awakening</li>
          <li>sexual dysfunction</li>
          <li>
            stiffness after waking or resting for an extended period of time
          </li>
        </ul>
        <p>
          Often, overlapping conditions such as TMJD, IBS, headaches and
          migraines, anxiety and depression, and environmental sensitivities
          co-exist with fibromyalgia.
        </p>
        <a className="text-blue-700" href="https://fibroandpain.org/">
          fibroandpain.org
        </a>
        <h2>You are NOT alone!</h2>
      </div>
    </div>
  </Layout>
)

export default FibromyalgiaChronicPain

export const query = graphql`
  query {
    file(relativePath: { eq: "you are not alone.png" }) {
      childImageSharp {
        fixed(width: 480, height: 360) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
